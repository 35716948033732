<template>
  <ion-header mode="ios">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button
          :default-href="userTypeId === 6 || userTypeId === 12 ? '/b2b/notification' : '/sale/notification'"
          text=""
          :icon="chevronBackOutline"
          @click="closeModalUpdatePrice"
        >
        </ion-back-button>
      </ion-buttons>
      <ion-label class="fs-3">{{ title || $t('product_price_update') }}</ion-label>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { chevronBackOutline } from 'ionicons/icons';
import { ref } from 'vue';
export default {
  props: ['userType', 'title'],
  emits: ['close-modal'],
  setup(props) {
    const userTypeId = ref(props.userType);
    return {
      userTypeId,
      chevronBackOutline
    };
  },
  methods: {
    closeModalUpdatePrice() {
      this.$emit('close-modal');
    }
  }
};
</script>
<style src="./styles.scss" lang="scss" scoped></style>
